/** @format */

import styled from "styled-components";
import { Link } from "react-router-dom";
import BackgroundLg from "../assets/background/homepageLg.svg";
import BackgroundMobile from "../assets/background/homepage_mobile.svg";
import breakpoints from "../styles/breakpoints";
import TyltLogo from "../assets/tylt-logo.png";
import Facebook from "../assets/social/fb.svg";
import Twitter from "../assets/social/twitter.svg";
import Insta from "../assets/social/insta.svg";
import Linkedin from "../assets/social/linkedin.svg";
import Youtube from "../assets/social/youtube.svg";
import Telegram from "../assets/social/telegram.svg";

const HomeStyledDiv = styled.div`
  height: calc(100vh - 88px - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10vw;
  background: url(${BackgroundLg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media ${breakpoints.mobile} {
    height: calc(100vh - 56px - 56px);
    padding: 0 1rem;
    background: url(${BackgroundMobile});
  }
`;
const TitleContainer = styled.div`
  font-size: 3rem;
  margin-bottom: 1.5rem;
`;

const ReimaginedSpan = styled.span`
  display: inline-block;
  background: linear-gradient(190.9deg, #00e5ff, #4676e0);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const DescriptionDiv = styled.p`
  width: min(600px, 90%);
  line-height: 1.5;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  margin-top: 1rem;

  @media ${breakpoints.mobile} {
    width: 100%;
    margin-bottom: 3rem;
  }
`;

const ProductIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
`;

const ProductIconWrapper = styled(Link)`
  display: grid;
  place-items: center;
  margin-right: 1rem;
  padding: 1.5px;
  background: ${(props) =>
    `linear-gradient(135deg, ${props.type === "tl" ? "#2236aa" : "#31429B"
    } 0%, ${props.type === "tl" ? "#24dc8e" : "#53BEED"} 100%)`};
  border: none;
  border-radius: 10px;
  box-shadow: ${(props) => `-3px -3px 5px 0.5px rgba(${props.type === "tl" ? "34, 54, 170" : "49, 66, 155"
    }, 0.25),
    3px 3px 5px 0.5px rgba(${props.type === "tl" ? "36, 220, 142" : "83, 190, 237"
    }, 0.25)`};

  @media ${breakpoints.mobile} {
    padding: 1px;
    border-radius: 5px;
  }
`;

const ProductIcon = styled.div`
  padding: 0.5rem 0.75em;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  max-width: calc(26px + 1rem);
  display: grid;
  place-items: center;
  overflow: hidden;
  transition: max-width 175ms ease-out;

  & img {
    display: block;
    height: 26px;
    width: auto;
    position: relative;
    left: ${(props) => (props.type === "tl" ? "2px" : "0")};
    backface-visibility: none;
    transition: left 175ms;
  }

  &:hover {
    max-width: 312px;

    & img {
      left: 0;
    }
  }

  @media ${breakpoints.mobile} {
    padding: 0.5rem;
    border-radius: 5px;
    max-width: calc(25px + 1rem);

    & img {
      display: block;
      height: 25px;
      left: ${(props) => (props.type === "tl" ? "6px" : "3px")};
      pointer-events: none;
    }

    &.clicked {
      max-width: 312px;

      & img {
        left: 0;
      }
    }
  }
`;

const CardContainer = styled.div`
margin-top: 1rem;
  width: 300px;              /* Adjust as needed */
  border-radius: 12px;       /* Matches the rounded corners in the screenshot */
  overflow: hidden;          /* Ensures child elements won't exceed rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: sans-serif;   /* Example font; customize to your preference */
  background-color: #D2E9F3;
  padding: 5px;
  border-radius: 20px;

`;


const LogoSection = styled.div`
  /* Layout and spacing */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  border-radius: 30px;

  /* 
    We use two backgrounds:
    1) A solid white background clipped to the 'padding-box' so it doesn't paint over the border area.
    2) A gradient background clipped to the 'border-box' so it appears in the border area.
  */
  background: 
    linear-gradient(#fff, #fff) padding-box,
    linear-gradient(135deg, #2236AA 0%, #24DC8E 100%) border-box;

  /* 
    We set a transparent border. 
    The second background (gradient) will show through this border area.
  */
  border: 2px solid transparent;

  /* 
    background-clip ensures the first background 
    only fills the content area (padding-box), 
    while the second background fills up to the border-box.
  */
  background-clip: padding-box, border-box;
`;


// Styled image for the Tylt logo
const Logo = styled.img`
  width: 110px;              /* ~135.91 from your screenshot */
  height: auto;
`;
const LogoLink = styled.a`
  display: inline-block; /* Ensures the anchor fits the image nicely */
`;

// Bottom section (light background) for the social icons
const SocialSection = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 8px 8px;
  height: 60px;
  width: 100%;
`;

// Individual social icon link
const SocialLink = styled.a`
  color: #000;               /* Icon color (black) */
  font-size: 10;           /* Adjust icon size as desired */
  text-decoration: none;
  margin: 10px;

  &:hover {
    opacity: 0.8;            /* Simple hover effect */
  }
`;

const Image = styled.img`
    width: 24px;
    height: 24px;
`;
const Home = () => {
  const onBtnClick = (ev) => {
    ev.target.classList.add("clicked");
    setTimeout(() => ev.target.classList.remove("clicked"), 700);
  };

  return (
    <HomeStyledDiv>
      {/* title */}
      <TitleContainer>
        The financial markets,
        <br /> <ReimaginedSpan>reimagined</ReimaginedSpan>
      </TitleContainer>
      <DescriptionDiv>
        TLX Labs pioneers technologies that bridge the gap between people and
        the financial world.
      </DescriptionDiv>
      <div>
      </div>
      <CardContainer>
        <LogoSection>
          <LogoLink
            href="https://tylt.money/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Logo src={TyltLogo} alt="Tylt Logo" />
          </LogoLink>
        </LogoSection>
        <SocialSection>

          <SocialLink
            href="https://x.com/tyltmoney"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Twitter} alt="Twitter" />

          </SocialLink>
          <SocialLink
            href="https://www.instagram.com/tylt.money/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Insta} alt="Instagram" />

          </SocialLink>
          <SocialLink
            href="https://www.linkedin.com/company/tylt-money/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Linkedin} alt="Linkedin" />

          </SocialLink>
          <SocialLink
            href="https://www.youtube.com/@Tyltmoneyhq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Telegram} alt="Telegram" />

          </SocialLink>
          <SocialLink
            href="https://www.youtube.com/@Tyltmoneyhq/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src={Youtube} alt="Youtube" />

          </SocialLink>
        </SocialSection>
      </CardContainer>
      {/* <ProductIconContainer>
        <ProductIconWrapper
          type="tl"
          to="/product/trading-leagues"
          onClick={onBtnClick}
        >
          <ProductIcon type="tl">
            <img src={TLlogo_full} alt="tl_logo_symbol" />
          </ProductIcon>
        </ProductIconWrapper>
        <ProductIconWrapper
          type="tr"
          to="/product/trading-rooms"
          onClick={onBtnClick}
        >
          <ProductIcon type="tr">
            <img src={TRRoomLogo_full} alt="tr_logo_symbol" />
          </ProductIcon>
        </ProductIconWrapper>
      </ProductIconContainer> */}
    </HomeStyledDiv>
  );
};

export default Home;
